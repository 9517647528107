import { authorize } from '@smartapp/auth-lib'
import { Settings } from 'luxon'
import { initializeApp } from 'firebase/app'
import * as firebaseui from 'firebaseui'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import i18n from './i18n'

const locale = window.navigator.language.split('-')[0]
if (i18n.global.availableLocales.includes(locale)) i18n.global.locale.value = locale

Settings.defaultLocale = i18n.global.locale.value

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  application: process.env.VUE_APP_APP_NAME,
  country: process.env.VUE_APP_COUNTRY
}

initializeApp(config)

export let globalProperties = {}
const ui = new firebaseui.auth.AuthUI(getAuth())
const auth = getAuth()

onAuthStateChanged(auth, async firebaseUser => {
  if (firebaseUser) {
    authorize(config, firebaseUser, process.env.VUE_APP_AUTH_SMART)
      .then(({ user, roles }) => {
        globalProperties = {
          firebaseUser,
          user,
          roles,
          arfFeatures: process.env.VUE_APP_ARF_FEATURES,
          isUserFranchise: user?.site?.contractType === 'FRA' || user?.employeeType === 'Affiliate'
        }

        if (process.env.VUE_APP_ARF_FEATURES) {
          fetch(`${process.env.VUE_APP_BACKEND_SMART}/application/${process.env.VUE_APP_APP_NAME}`, {
            headers: {
              Authorization: `Bearer ${firebaseUser.accessToken}`,
              project_id: process.env.VUE_APP_FIREBASE_PROJECT_ID,
              application: process.env.VUE_APP_APP_NAME,
              country: process.env.VUE_APP_COUNTRY
            }
          })
            .then(async res => {
              globalProperties.appInfos = (await res.json()).result
            })
            .catch(console.error)
        }

        import(/* webpackChunkName: "createApp" */ './createApp').then(createApp => {
          if (window.screen.width >= 600) createApp.createApp({ firebaseUser, user, roles })
          else createApp.createMobileApp({ firebaseUser, user, roles })
        })
      })
      .catch(reason => {
        console.warn(reason)
        if (reason.status === 403) {
          globalProperties = { firebaseUser, user: reason.user }
          import(/* webpackChunkName: "createApp" */ './createApp').then(createApp => {
            createApp.createForbiddenApp({ ...reason, firebaseUser })
          })
        } else {
          window.location.href = '/error.html'
        }
      })
  } else {
    ui.start('#firebase-ui', {
      signInFlow: 'popup',
      signInSuccessUrl: '/',
      signInOptions: [
        {
          provider: 'oidc.pass',
          buttonColor: '#E0001A',
          iconUrl: 'null',
          fullLabel: i18n.global.t('common.login-to'),
          providerName: 'pilotv3'
        }
      ]
    })
  }
})
